import { useDispatch } from "react-redux";
import { userUnAuthorized } from "../redux/actions/commonAction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const unAuthorized = () => {
  // const dispatch = useDispatch();
  // Your utility function logic here
  console.log("Utility function called");
  userUnAuthorized();
};

export const copyToClipboard = (data) => {
  return typeof data == "object" ? (
    data
  ) : data ? (
    <span
      title="Click to copy"
      id={data}
      className="clickToCopy"
      onClick={() => {
        navigator.clipboard.writeText(data);
        //window.alert("Phone Number Copied!")
        const element = document.querySelector(".isclickedText");
        if (element) {
          element.textContent = "Text Copied to Clipboard !";
          element.style.padding = "10px";
          setTimeout(() => {
            element.textContent = "";
            element.style.padding = "0px";
          }, 1000);
        }
      }}
    >
      {data}
    </span>
  ) : ""
};
